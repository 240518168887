@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
	gap: 24px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 300px;
	
	@include on768 {
		flex-direction: row;
		align-items: end;
		gap: 24px;
		max-width: none;
	}
	
  @include on1024 {
  }

	&__part {
		display: flex;
		flex-direction: column;
	}
}

.text {
  display: block;
	text-transform: uppercase;
  color: $color_red_dark;
  margin-bottom: 8px;
}

.th {
	background-color: $color_red_dark;
	color: #fff;
	text-align: center;
}

.td {
	&:nth-child(n + 3) {
		text-align: center;
	}

	&__filtered {
		color: $color_green_dark;
		cursor: pointer;
	}
	&__unfiltered {
		color: $color_up_down_load;
		cursor: pointer;
	}
	&__ignored {
		color: $color_error;
		cursor: pointer;
	}
}

tbody > tr > td:nth-child(3) > span {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
}

tbody > tr > td:nth-child(5) > span {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
}