@import './mixins';

.main {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;

}

.snowflakes {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none; /* Щоб сніжинки не заважали клікам */
	&::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 10vh;
		background: linear-gradient(
			to bottom,
			rgba(161, 184, 196, 0.8),
			rgba(255, 255, 255, 0)
		);
		pointer-events: none;
	}
	
	&::after {
		content: '';
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 20vh;
		background: linear-gradient(
			to top,
			rgba(161, 184, 196, 0.8),
			rgba(255, 255, 255, 0)
		);
		pointer-events: none; /* Щоб не заважати клікам */
	}
}

/* Сніжинка */
.snowflake {
	position: absolute;
	top: 0;
	background: rgb(140, 163, 178);
	border-radius: 50%;
	animation: fall linear infinite;
	pointer-events: none;
}

/* Анімація сніжинки */
@keyframes fall {
	0% {
		transform: translateY(0);
		opacity: 0.9;
	}
	50% {
		opacity: 0.4;
	}
	95% {
		opacity: 0;
	}
	100% {
		transform: translateY(100vh);
		opacity: 0;
	}
}

.snowflake:nth-child(1) {
	width: 6px;
	height: 6px;
	filter: blur(3px);
	left: 14%;
	animation-duration: 8s;
}
.snowflake:nth-child(2) {
	width: 4px;
	height: 4px;
	filter: blur(2px);
	left: 8%;
	animation-duration: 5s;
}
.snowflake:nth-child(3) {
	width: 3px;
	height: 3px;
	filter: blur(1px);
	left: 28%;
	animation-duration: 6.5s;
}
.snowflake:nth-child(4) {
	width: 6px;
	height: 6px;
	filter: blur(3px);
	left: 36%;
	animation-duration: 7s;
}
.snowflake:nth-child(5) {
	width: 4px;
	height: 4px;
	filter: blur(2px);
	left: 44%;
	animation-duration: 5.3s;
}
.snowflake:nth-child(6) {
	width: 3px;
	height: 3px;
	filter: blur(1px);
	left: 48%;
	animation-duration: 6.2s;
}
.snowflake:nth-child(7) {
	width: 6px;
	height: 6px;
	filter: blur(3px);
	left: 68%;
	animation-duration: 6s;
}
.snowflake:nth-child(8) {
	width: 4px;
	height: 4px;
	filter: blur(2px);
	left: 54%;
	animation-duration: 5.5s;
}
.snowflake:nth-child(9) {
	width: 3px;
	height: 3px;
	filter: blur(1px);
	left: 59%;
	animation-duration: 7.5s;
}
.snowflake:nth-child(10) {
	width: 6px;
	height: 6px;
	filter: blur(3px);
	left: 79%;
	animation-duration: 7s;
}
.snowflake:nth-child(11) {
	width: 4px;
	height: 4px;
	filter: blur(2px);
	left: 93%;
	animation-duration: 6s;
}
.snowflake:nth-child(12) {
	width: 3px;
	height: 3px;
	filter: blur(1px);
	left: 85%;
	animation-duration: 5.2s;
}

@keyframes fall {
	0% {
		transform: translateY(0) translateX(0);
		opacity: 0.9;
	}
	50% {
		opacity: 0.4;
	}
	95% {
		opacity: 0;
	}
	100% {
		transform: translateY(100vh) translateX(50px);
		opacity: 0;
	}
}
