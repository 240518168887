@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.btn {
	background-color: initial;
	border: none;
	outline: none;
}

.upDown {
	width: 12px;
	height: 12px;

	@include on768 {
		width: 16px;
		height: 16px;
	}
	@include on1024 {
		width: 22px;
		height: 22px;
	}

	&:hover {
		cursor: pointer;
		animation: btnAnimation 1s linear infinite;
	}

	&__head {
		width: 14px;
		height: 14px;

		@include on768 {
			width: 20px;
			height: 20px;
		}
		@include on1024 {
			width: 32px;
			height: 32px;
		}

		&:hover {
			cursor: initial;
			animation: none;
		}
	}

	&__notUploaded {
		&:hover {
			cursor: initial;
			animation: none;
		}
	}



	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__P {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		fill: $color_up_down_load;
		stroke-miterlimit: 10;
		fill-rule: nonzero;
		opacity: 1;
		
    &__head {
			fill: #FFF;
    }

		&__notUploaded {
			fill: $color_error;
		}

		&__notDownloaded {
			fill: $color_yellow;
		}
		&__isDownloaded {
			fill: $color_green_dark;
		}
	}
}

@keyframes btnAnimation {
	0% {transform: scale(1)}
	25% {transform: rotate(10deg) scale(1.1)}
	50% {transform: scale(1.2)}
	74% {transform: rotate(-10deg) scale(1.1)}
	100% {transform: rotate(0) scale(1)}
}