@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.researcher {
  flex: 1;
  margin: 0 16px 24px;

  @include on768 {
    margin: 0 32px 24px;
  }
  @include on1024 {
    margin: 0 52px 24px;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  color: $color_red_dark;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 24px;
}

.choice {
  text-align: center;
  color: $color_green_dark;
}
