@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.add {
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  max-width: 500px;


  @include on768 {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @include on1024 {
    max-width: 768px;
  }
}

.text {
  display: block;
  text-align: center;
  color: $color_red_dark;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid $color_red_dark;
  outline: none;
  color: $color_red_dark;
  margin-bottom: 8px;

  @include on768 {
    max-width: 500px;
  }
}
