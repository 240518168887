@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.btn {
  min-width: max-content;
  background-color: initial;
  border: 1px solid $color_green_light;
  border-radius: 4px;
  outline: none;
  padding: 6px 12px;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(42, 37, 40, 0.922);
  transition: all .5s;

  &:hover {
    cursor: pointer;
    background-color: $color_green_light;
    border-color: $color_grey;
    color: #FFF;
  }

  &:focus, &:active {
    border-color: $color_grey;
  }

  @include on768 {
    padding: 12px 24px;
    font-size: 14px;
  }
}

.isActive {
  background-color: $color_green_light;
  border-color: $color_grey;
  color: #FFF;
}