@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.area {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $color_bg_shadow;
	z-index: 10;
}

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	padding: 24px;
	border-radius: 5px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	gap: 24px;
	overflow: hidden;
}

.exit {
	display: flex;
	justify-content: end;
}

.tableContainer {
	max-height: 60vh;
	overflow-y: auto;
}

.th {
	position: sticky;
	top: -1px;
	left: 0;
	background-color: $color_red_dark;
	color: #fff;
	text-align: center;
}

.th,
.td {
	font-size: 14px;

	&:nth-child(n + 2) {
	  text-align: center;
	  width: 14%;
	}

	&:nth-last-child(-n+2) {
		text-align: center;
		width: 6%;
	}
}
