@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.add {
  margin: 24px 0 24px;
  padding: 24px 0 24px;
  border-top: 1px dashed $color_red_dark;
  border-bottom: 1px dashed $color_red_dark;
}

.select {
  width: 100%;
  max-width: 300px;
  padding: 10px 8px;
  border-radius: 4px;
  outline: none;
  color: $color_red_dark;
  margin-bottom: 24px;

  @include on768 {
    max-width: 330px;
  }
  @include on1024 {
    max-width: 350px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 300px;

  &__wrap {
    display: flex;
    align-items: center;
    gap: 8px;
    
    &__2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  @include on768 {
    max-width: 360px;
  }

  @include on1024 {
    flex-direction: row;
    justify-content: space-between;
  }
}

.input {
  width: 180px;
  height: min-content;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid $color_red_dark;
  outline: none;
  color: $color_red_dark;

  @include on768 {
    width: 350px;
  }
}
