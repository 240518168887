@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 20px;
  width: 100%;
  max-width: 400px;
}

.select {
  width: 100%;
  padding: 10px 8px;
  border-radius: 4px;
  outline: none;
  color: $color_red_dark;
  margin-bottom: 32px;

  @include on768 {
    max-width: 500px;
    margin-bottom: 42px;
  }
  @include on1024 {
    margin-bottom: 54px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @include on768 {
    gap: 22px;
  }
  @include on1024 {
    gap: 32px;
  }
}

.text {
  display: block;
  text-align: center;
  color: $color_red_dark;
}

.btn{
  display: flex;
  justify-content: center;
}

.errorText {
  display: block;
  text-align: center;
  color: $color_error;
  margin-bottom: 8px;
}
