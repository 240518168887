@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color_bg_shadow;
  z-index: 10;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;;
  height: max-content;
  padding:24px;
  border-radius: 5px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @include on768 {
    width: 70%;;
    gap: 44px;
  }
  @include on1024 {
    width: 60%;;
    gap: 56px;
  }
}

.exit {
  display: flex;
  justify-content: end;
}

.text {
  display: block;
  text-align: left;
  color: $color_red_dark;
  margin-bottom: 8px;

  @include on768 {
    margin-bottom: 16px;
  }
}
