@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.del {
	width: 12px;
	height: 12px;

	&__head {
		cursor: auto;
		animation: none;
		
		&:hover {
		}
	}
	
	@include on768 {
		width: 14px;
		height: 14px;
	}
	@include on1024 {
		width: 16px;
		height: 16px;
	}

	&__G {
		stroke: none;
		stroke-width: 0;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: none;
		fill-rule: nonzero;
		opacity: 1;
	}

	&__P1 {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: $color_error;
		fill-rule: nonzero;
		opacity: 1;

		&__head {
			fill: #fff;
		}
	}

	&__P2 {
		stroke: none;
		stroke-width: 1;
		stroke-dasharray: none;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-miterlimit: 10;
		fill: #fff;
		fill-rule: nonzero;
		opacity: 1;
		
		&__head {
			fill: initial;
		}
	}
	
	&:hover:not(.del__head) {
		cursor: pointer;
		animation: btnAnimation 1s linear infinite;
	}
}

@keyframes btnAnimation {
	0% {
		transform: scale(1);
	}
	25% {
		transform: rotate(8deg) scale(1.1);
	}
	50% {
		transform: scale(1.3);
	}
	74% {
		transform: rotate(-8deg) scale(1.1);
	}
	100% {
		transform: rotate(0) scale(1);
	}
}
