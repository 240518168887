@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.cart {
}

.th {
	background-color: $color_red_dark;
	color: #fff;
	text-align: center;
	padding: 4px;
}

.td {
	padding: 4px;

  &__2 {
    text-align: center;
  }
}

// .td__wrapper {
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	padding-right: 6px;
// }

.add {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	padding: 24px 0 24px;
	margin: 24px 0 24px;
	border-top: 1px dashed $color_red_dark;
	border-bottom: 1px dashed $color_red_dark;
}

.input {
	width: 60%;
	max-width: 300px;
	padding: 10px 8px;
	border-radius: 4px;
	border: 1px solid $color_red_dark;
	outline: none;
	color: $color_red_dark;
}

.text {
	color: $color_red_dark;
	margin-bottom: 8px;
}

.collect {
	display: flex;
	justify-content: center;

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: end;
		width: 100%;
		gap: 24px;
	}

	&__wrap2 {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__text {
		color: $color_green_dark;
	}
}
