@import '../../style/utiles/variables';
@import '../../style/utiles/mixins';

.input {
  width: 100%;
  max-width: 360px;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid $color_red_dark;
  outline: none;
  color: $color_red_dark;
  height: max-content;

  @include on768 {
    min-width: 256px;
  }
}

.fileLabel {
  width: 100%;
  max-width: 360px;
  padding: 20px 8px;
  border-radius: 4px;
  border: 1px dashed $color_red_dark;
  outline: none;
  cursor: pointer;
  color: $color_red_dark;

  & > p {
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;

    &:last-child {
      margin-top: 8px;
      text-decoration: underline;
    }
  }

  @include on768 {
    min-width: 256px;
  }
}